import React, { Component } from 'react'
import Layout from '../components/Layout'
import { Timeline, TimelineProps } from '../components/Timeline'

class About extends Component<{},{
    identity: any,
    experiences: Array<TimelineProps>
}> {
    constructor(props:any) {
        super(props)
        this.state = {
            identity: {
                name: "Muhammad Khotib",
                age: new Date().getFullYear()-1998,
                birth_of_date: "10/03/1998",
                hometown: "Situbondo",
                current_address: "Jl. Monjali, gang bopong no 5, Ngaglik, Kec. Nandan, Kota Sleman. Daerah Istimewa Yogyakarta",
                education: {
                    degree: "Bachelor",
                    study_field: "Information Systems",
                    graduation_year: "2015",
                    college: "Sepuluh Nopember Institute of Technology"
                },
                work_experiences: [{
                        company_name: "Self Employee",
                        division: "-",
                        position: "Full stack developer",
                        year: "2016 - 2019"
                    },
                    {
                        company_name: "PT. Docotel Technology",
                        division: "PT. Solusi Pembayaran Technology",
                        position: "Sofware Engineer",
                        year: "2019 - now"
                    },
                ]
            },
            experiences: [{
                    year: "2015",
                    event: "Started college at Information Systems Department of Sepuluh Nopember Institute of Technology"
                },
                {
                    year: "2016",
                    event: "Started to being Self Emplyed Web developer and learn many new things from friends, client and seniors"
                },
                {
                    year: "2019",
                    event: "Graduated from Information Systems Department of Sepuluh Nopember Institute of Technology"
                },
                {
                    year: "2019",
                    event: "Started new job as Web Developer at PT. Docotel Technology SPE Division"
                },
            ]
        }
    }
    render() {
        return (
            <Layout>
                 <section className="content-section-md">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <h2>About me</h2>
                            </div>
                            <div className="col-md-6 mb-4">
                                {this.state.experiences.map((val, idx) => {
                                    return <Timeline 
                                    year={val.year}
                                    event={val.event}
                                    />
                                })}
                            </div>                    
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}

export default About
