import React from 'react'

export interface TimelineProps {
    year: string
    event: string
}

export const Timeline = (props: TimelineProps) => {
    return(
        <div className="timeline">
            <div className="timeline-box">
                <p><b>{props.year}</b></p>
                <p>{props.event}</p>
            </div>
            <div className="circle"></div>
        </div>
    )
}